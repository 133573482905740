import React, { useEffect, useState } from 'react';
import Modal from '../FormSelectorModal';
import { useRouter } from 'next/router';

export default function Footer(): React.ReactElement {
  const [showModal, setShowModal] = useState(false);
  const [hideCTA, setHideCTA] = useState(true);
  const router = useRouter();
  const email = `contact@assistonline.uk`;

  useEffect(() => {
    if (window) {
      const isCTAAvailable = [
        '/',
        '/terms-and-conditions',
        '/privacy-policy',
      ].includes(window.location.pathname);
      setHideCTA(isCTAAvailable);
    }
  }, [router]);

  return (
    <>
      {showModal && <Modal setShowModal={setShowModal} showModal={showModal} />}
      <div className="bg-gray-200 bottom-0">
        <div
          className={`flex xl:flex-row flex-col  xl:items-start bottom-0 ${
            !hideCTA
              ? 'justify-center items-center gap-0 sm:gap-96'
              : 'justify-between items-center gap-6'
          } min-w-xl sm:px-24 py-12`}
        >
          <h1 className="font-black uppercase text-xl text-center">
            <a href="/">Driving Licence Application</a>
          </h1>
          <div className="flex flex-col justify-start sm:items-start gap-3 sm:gap-6 ">
            <h1 className="font-black text-xl text-center">Contact us</h1>

            <a className="text-center break-all" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
          <button
            className={`text-white px-12 py-4 text-xl bg-red-500 hover:bg-red-600 rounded-2xl font-black w-auto ${
              !hideCTA && 'hidden'
            }`}
            onClick={() => setShowModal(true)}
          >
            Start Your Application
          </button>
        </div>
        <div className="flex items-center justify-center w-full">
          <hr className="w-3/4" />
        </div>
        <div className="flex  lg:flex-row flex-col items-center lg:items-start justify-between min-w-xl sm:px-24 gap-6 sm:gap-0 py-12">
          <p>
            ©Copyright {new Date(Date.now()).getFullYear()} | All Rights
            Reserved
          </p>
          <div className="flex items-center sm:gap-6 gap-4 ">
            <p>
              <a href="/terms-and-conditions">Terms & Conditions</a>
            </p>
            <p>
              <a href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
